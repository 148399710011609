<template>
    <div class="app-input app-input-location-autocomplete"
        :class="{ disabled, error, required, typing, [textTransform]: textTransform }"
        @click="focus"
    >
        <div v-if="error" class="error-message"><i></i><span>{{ error }}</span></div>
        <label v-else><span>{{ label }}<i v-if="required">*</i></span></label>

        <input :value="value"
            type="text"

            :disabled="disabled"
            :maxlength="maxlength"
            :placeholder="placeholder"

            @focus="handleFocus"
            @blur="handleBlur"
            @keyup.enter="onEnter"

            @input="onChangeWord($event.target.value)"

            ref="field"
        >

        <div class="places" :class="{ show: opened && with_predictions }" @click.stop="close" ref="scroll">
            <ul class="predictions">
                <li v-for="(prediction, key) in predictions" :key="key" @click="onPlaceSelect(prediction)" v-html="prediction.location"></li>
            </ul>

            <button class="btn btn-link" @click="enterManually" v-if="withManually">Enter address manually</button>
        </div>
    </div>
</template>

<script>
import SimpleBar from 'simplebar'
import 'simplebar/dist/simplebar.css'

import { mapGetters } from 'vuex'

import appInput from '@/components/app-input'

import errMessage from '@/helpers/errMessage'
import latinise from '@/helpers/latinise'

export default {
    extends: appInput,

    props: {
        withManually:          { type: Boolean, default: true               },
        showLocationProvider:  { type: Boolean, default: false               },
        // withLocationProviders:  { type: Array,  default: ['CHORUS','SLAM']          },
        withLocationProviders: { type: Array,   default: function () { return ['GOOGLE'] } },
        withLocationProvider: { type: String, default: '' },
    },

    data() {
        return {
            simple_bar: null,

            opened: false,
            predictions: [],

            request_id: 0,
            debounce_timeout: null,
            last_search_at: 0,
        }
    },

    mounted() {
        this.simple_bar = new SimpleBar(this.$refs.scroll, {
            autoHide: false
        })
    },

    methods: {
        open() {
            if (!this.opened) {
                this.opened = true

                this.$nextTick(()=>{
                    document.addEventListener('click', this.handleClickOutside)
                })
            }
        },
        close() {
            if (this.opened) {
                this.opened = false

                document.removeEventListener('click', this.handleClickOutside)
            }
        },

        handleClickOutside(event) {
            if (!this.$el.contains(event.target)) {
                this.close()
            }
        },

        onChangeWord(word, force) {
            var timeNow = new Date()

            if (word && ((this.last_search_at + 1000) < timeNow.getTime())) {
                // Force a search at least once per second, even when typing
                force = true
            }

            if (this.debounce && !force) {
                this.clearDebounce()

                this.debounce_timeout = setTimeout(() => {
                    this.onChangeWord(word, true)

                    this.debounce_timeout = null
                }, this.debounce)
            } else if (word) {
                clearTimeout(this.debounce_timeout)

                const request_id = ++this.request_id

                const payload = {
                    AddressText: latinise(word).trim(),

                    noauth: true,
                    SPID: this.current_spid,
                    // SearchAll: true,
                }

                // Do the following to prevent the address search API from being
                // SLAMMED by very expensive (short) requests from the FE
                const spaceCount = (payload.AddressText.split(" ").length - 1);
                if ((spaceCount > 1 && payload.AddressText.length > 5)
                    || (spaceCount <= 1 && payload.AddressText.length > 7)
                ) {
                    if (this.withLocationProviders) {
                        payload.LocationProviders = this.withLocationProviders
                    } else if (this.withLocationProvider) {
                        payload.LocationProviders = [ this.withLocationProvider ]
                    }

                    this.last_search_at = timeNow.getTime()

                    this.$store.dispatch('api_location/FindAddress', payload)
                        .then(response => {
                            const predictions = response.Locations

                            if (request_id === this.request_id) {
                                if (predictions && predictions.length > 0) {
                                    const match_regexp_operators = /[|\\{}()[\]^$+*?.-]/g
                                    const pattern = new RegExp(word.replace(match_regexp_operators, '\\$&').replace(' ', '\\s'), 'gi')

                                    this.predictions = predictions.map(item => {
                                        item.location = item.Formatted.replace(pattern, '<b>$&</b>')

                                        if (this.showLocationProvider) {
                                            switch (item.LocationProvider) {
                                                case "SLAM":
                                                    item.location = '[ANY] ' + item.location
                                                    break;
                                                default:
                                                    item.location = '[' + item.LocationProvider + '] ' + item.location
                                            }  
                                        }

                                        if (!item.location) {
                                            item.location = [
                                                item.StreetName,
                                                item.StreetType,
                                                item.City,
                                                item.State
                                            ].join(' ').trim()

                                            if (item.location) {
                                                item.Formatted = item.location
                                            }
                                        }

                                        return item
                                    })

                                    this.open()
                                } else {
                                    this.predictions = []
                                }
                            }
                        })
                        .catch(error => {
                            this.$store.dispatch('addToast', {
                                message: errMessage(error),
                                type: 'error',
                                delay: 5000,
                            })

                            this.predictions = []
                        })
                }
            } else {
                this.close()
            }

            this.$emit('input', word)
            this.$emit('change', word)
        },

        onPlaceSelect(prediction) {
            const address = prediction.Formatted

            this.$emit('input', address)
            this.$emit('change-place', address, prediction)
            this.$emit('change-address', address)
        },

        clearDebounce() {
            if (this.debounce_timeout !== null) {
                clearTimeout(this.debounce_timeout)

                this.debounce_timeout = null
            }
        },

        enterManually() {
            this.$emit('enter-manually')
        },

        onChange(value) {
            this.$emit('change-address', value)
        },
    },

    computed: {
        ...mapGetters([
            'current_spid',
            'broadband_location_search',
        ]),

        with_predictions() {
            return this.predictions && this.predictions.length > 0
        },
    },
}
</script>

<style lang="scss">
.app-input.app-input-location-autocomplete {
    $option-margin: 8px;
    $option-padding: 4px;

    $option-line-height: 24px;

    $option-height: $option-margin * 2 + $option-padding * 2 + $option-line-height;

    .simplebar-track {
        &.simplebar-vertical {
            top: 15px;
            right: 10px;
            bottom: 15px;
            width: 4px;
            background-color: var(--color-scrollbar-bg);
            border-radius: 2px;

            .simplebar-scrollbar {
                &::before {
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    background-color: var(--color-scrollbar-thumb-bg);
                    border-radius: 2px;
                    opacity: 1;
                }
            }
        }
    }

    .error-message {
        position: absolute;
        bottom: 100%;
        left: 0;
        display: flex;
        width: 100%;
        max-width: 100%;
        font-size: 14px;
        line-height: 24px;
        color: var(--color-error);

        i {
            display: inline-block;

            width: 24px;
            height: 24px;

            @include icon-before($icon-attention);
        }

        span {
            @include text-overflow();
        }
    }

    .places {
        position: absolute;
        top: calc(100% + 4px);
        left: 0;
        right: 0;
        z-index: $z-index-dropdown;
        max-height: 3 * $option-height;
        font-size: 18px;
        border-radius: $border-radius-secondary;
        background: var(--color-autocomplete-bg);
        box-shadow: var(--box-shadow-tertiary);

        // opacity: 0;
        // visibility: hidden;
        display: none;

        &.show {
            // opacity: 1;
            // visibility: visible;
            display: block;
        }

        ul {
            li {
                margin: $option-margin 0;
                padding: $option-padding 24px;
                line-height: $option-line-height;
                color: var(--color-autocomplete-option);
                cursor: pointer;

                &:hover {
                    background-color: var(--color-autocomplete-option-hover-bg);
                }
            }
        }

        .btn {
            width: 100%;
            height: $option-height;
            padding: 0 16px;
            text-align: left;

            &:hover {
                background-color: var(--color-autocomplete-option-hover-bg);
            }
        }
    }
}

@media (max-width: $tablet-size) {}

@media (max-width: $mobile-size) {
    .app-input.app-input-location-autocomplete {
        .places {
            font-size: 14px;
            max-height: 116px;

            ul {
                li {
                    line-height: 20px;
                    margin: 8px 0;
                    padding: 4px 8px;

                    @include text-overflow();
                }
            }

            .btn {
                background-size: 100px;
            }
        }
    }
}
</style>