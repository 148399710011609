<template>
    <div class="app-autocomplete">
        <app-input
            v-model="search"

            :label="label"
            :required="required"
            :error="error"

            @input="onChange"
        />

        <div class="options" :class="{ show: is_open && has_options }" ref="scroll">
            <div class="scroll">
                <div class="option" v-for="(option, index) in options" :key="index">
                    <div class="title" @click="onSelect(option)">
                        <span>{{ option.value }}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import SimpleBar from 'simplebar'
import 'simplebar/dist/simplebar.css'

import appInput from '@/components/app-input'

export default {
    components: {
        appInput,
    },

    props: {
        options:      { type: Array,   required: true },
        label:        { type: String,  default: ''    },
        required:     { type: Boolean, default: false },
        error:        {                default: false },
        delay:        { type: Number,  default: 0     }, // The number of milliseconds to wait after typing
        maxdelay:     { type: Number,  default: 1000  }, // The maximum number of milliseconds to wait
        initialvalue: { type: Object,  default: null  },
    },

    data() {
        return {
            simple_bar: null,

            search: '',
            is_open: false,

            debounce: null,

            last_change_at: 0,
        }
    },

    computed: {
        has_options() {
            return this.options.length > 0
        }
    },

    methods: {
        onChange(value) {
            if (this.delay) {
                clearTimeout(this.debounce)

                var timeNow = new Date();
                if (value && ((this.last_change_at + this.maxdelay) < timeNow.getTime())) {
                    this.last_change_at = timeNow.getTime()
                    this.$emit('typing', value)
                    this.is_open = true
                    return
                }

                this.debounce = setTimeout(() => {
                    var timeThen = new Date();
                    this.last_change_at = timeThen.getTime()
                    this.$emit('typing', value)
                    this.is_open = true
                }, this.delay)
            } else {
                this.$emit('typing', value)
                this.is_open = true
            }
        },

        onSelect(option) {
            this.search = option.value

            this.$emit('select', option)

            this.is_open = false
        },

        reset() {
            this.search = ''
            this.is_open = false
        },
    },

    mounted() {
        if (this.initialvalue && this.initialvalue.value && !this.search) {
            this.search = this.initialvalue.value
        }

        this.simple_bar = new SimpleBar(this.$refs.scroll, {
            autoHide: false
        })
    },
}
</script>

<style lang="scss">
.app-autocomplete {
    $option-height: 32px + 8px * 2;

    position: relative;
    width: 100%;

    .simplebar-track {
        &.simplebar-vertical {
            top: 15px;
            right: 10px;
            bottom: 15px;
            width: 4px;
            background-color: var(--color-scrollbar-bg);
            border-radius: 2px;

            .simplebar-scrollbar {
                &::before {
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    background-color: var(--color-scrollbar-thumb-bg);
                    border-radius: 2px;
                    opacity: 1;
                }
            }
        }
    }

    .options {
        position: absolute;

        top: calc(100% + 4px);
        left: 0;
        right: 0;

        z-index: $z-index-dropdown;

        display: flex;
        flex-direction: column;

        max-height: 3 * $option-height;

        background-color: var(--color-autocomplete-bg);
        box-shadow: var(--box-shadow-tertiary);
        border-radius: $border-radius-primary;

        display: none;

        &.show {
            display: block;
        }

        .option {
            position: relative;
            display: flex;
            align-items: center;
            height: $option-height;

            .title {
                display: flex;
                align-items: center;
                width: 100%;
                height: 32px;
                padding: 0 24px;
                font-size: 18px;
                line-height: 1.33;
                color: var(--color-autocomplete-option);
                cursor: pointer;
                user-select: none;

                span {
                    @include text-overflow();
                }

                &:hover {
                    background-color: var(--color-autocomplete-option-hover-bg);
                }
            }
        }
    }
}
</style>