<template>
    <div class="app-input app-input-google-autocomplete-manually"
        :class="{ disabled, error, required, typing, [textTransform]: textTransform }"
        @click="focus"
    >
        <label><span>{{ label }}<i v-if="required">*</i></span></label>

        <input :value="value"
            type="text"

            :disabled="disabled"
            :maxlength="maxlength"
            :placeholder="placeholder"

            @focus="handleFocus"
            @blur="handleBlur"
            @keyup.enter="onEnter"

            @input="onChangeWord($event.target.value)"

            ref="field"
        >

        <div class="places" v-show="opened" @click.stop="close">
            <ul class="predictions" v-if="with_predictions">
                <li v-for="(prediction, key) in predictions" :key="key" @click="onPlaceSelect(prediction)" v-html="prediction.location"></li>
            </ul>

            <button class="btn btn-link" @click="enterManually">Enter address manually</button>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'

import appInput from '@/components/app-input'

import latinise from '@/helpers/latinise'

export default {
    extends: appInput,

    data() {
        return {
            service: null,

            opened: false,
            predictions: [],
        }
    },

    created() {
        this.initGoogleMaps()
    },

    methods: {
        initGoogleMaps() {
            if (this.GoogleMaps) {
                this.initAutocompleteService()
            } else {
                this.$store.dispatch('googleMapsInit')
                    .then(()=>{
                        this.initAutocompleteService()
                    })
                    .catch(()=>{})
            }
        },

        initAutocompleteService() {
            this.service = new google.maps.places.AutocompleteService()
        },

        open() {
            if (!this.opened) {
                this.opened = true

                this.$nextTick(()=>{
                    document.addEventListener('click', this.handleClickOutside)
                })
            }
        },
        close() {
            if (this.opened) {
                this.opened = false

                document.removeEventListener('click', this.handleClickOutside)
            }
        },

        handleClickOutside(event) {
            if (!this.$el.contains(event.target)) {
                this.close()
            }
        },

        onChangeWord(word) {
            if (word && word.length > 6) {
                const input = latinise(word)

                this.service.getPlacePredictions({
                    input,
                    componentRestrictions: {
                        country: 'nz'
                    },
                    // TODO: Find out how to filter by these fields: ['name', 'geometry.location', 'place_id', 'formatted_address'],
                    types: ['address'],
                }, (predictions, status) => {
                    if (status == google.maps.places.PlacesServiceStatus.OK) {
                        const match_regexp_operators = /[|\\{}()[\]^$+*?.-]/g
                        const pattern = new RegExp(input.replace(match_regexp_operators, '\\$&').replace(' ', '\\s'), 'gi')

                        this.predictions = predictions.map(item => {
                            item.location = item.description.replace(pattern, '<b>$&</b>')

                            return item
                        })
                    } else {
                        this.predictions = []
                    }

                    this.open()
                })
            } else {
                this.close()
            }

            this.$emit('input', word)
            this.$emit('change', word)
        },

        onPlaceSelect(prediction) {
            const address = prediction.description

            this.$emit('input', address)
            this.$emit('change-place', address, prediction)
            this.$emit('change-address', address)
        },

        enterManually() {
            this.$emit('enter-manually')
        },

        onChange(value) {
            this.$emit('change-address', value)
        },
    },

    computed: {
        ...mapGetters([
            'GoogleMaps',
        ]),

        with_predictions() {
            return this.predictions && this.predictions.length > 0
        },
    },
}
</script>

<style lang="scss">
.app-input.app-input-google-autocomplete-manually {
    $item-height: 40px;
    $item-line-height: 18px;

    .places {
        font-size: 16px;

        margin-top: 5px;
        padding: 0;
        border-radius: $border-radius-secondary;
        background: var(--color-autocomplete-bg);
        box-shadow: var(--box-shadow-tertiary);

        position: relative;
        z-index: 1;

        ul {
            li {
                padding: #{ ($item-height - $item-line-height) / 2 } 16px 0;
                line-height: $item-line-height;
                margin: 8px 0;
                cursor: pointer;

                &:after {
                    content: "";
                    display: block;
                    border-bottom: 1px solid var(--color-divider);
                    margin-top: #{ ($item-height - $item-line-height) / 2 };
                }

                &:hover {
                    background-color: var(--color-autocomplete-option-hover-bg);
                }
            }
        }

        .btn {
            width: 100%;
            height: $item-height;
            padding: 0 16px;
            text-align: left;
            background: transparent url(./images/powered-by-google.png) no-repeat right center;

            &:hover {
                background-color: var(--color-autocomplete-option-hover-bg);
            }
        }
    }
}

@media (max-width: $mobile-size) {
    .app-input.app-input-google-autocomplete-manually {
        .places {
            .btn {
                background-size: 100px;
            }
        }
    }
}
</style>